import dayjs from "dayjs";
import { useRef } from "react";

import Calendar from "@/components/Calendar";
import HovercardToggle, { HovercardToggleHandle } from "@/components/HovercardToggle";
import SelectableList, { SelectableListItem } from "@/components/SelectableList";
import TextEditor from "@/components/TextEditor";

interface EditableCellProps {
  /** Current unedited rendered text */
  text: string;
  /** Only relevant for "enum" types */
  items?: SelectableListItem[];
  /** Possible cell types, which governs how they are able to be edited */
  type?: "string" | "number" | "date" | "enum";
  /** Callback to finishing editing a cell */
  onEditCell: (text: number | string) => void;
}

/**
 * Use this component in our Table cells to have them be editable based on their type. For numbers
 * and strings, we show an input field that appears as editable text. For a date, we should a
 * Calendar in a hovercard, and for an enum (where we want to constrain the values a user can
 * choose), we show a SelectableList in a hovercard.
 *
 * To edit a cell, you double-click in.
 */
export default function EditableCell({
  text,
  onEditCell,
  items,
  type = "string",
}: EditableCellProps) {
  const inputRef = useRef<HTMLInputElement>();
  const hovercardToggleRef = useRef<HovercardToggleHandle>();

  const onDoubleClick = () => {
    // this is for string/number types
    if (inputRef.current) {
      inputRef.current.focus();
    } else if (hovercardToggleRef.current) {
      hovercardToggleRef.current.open();
    }
  };

  return (
    <span
      className="EditableCell"
      onDoubleClick={onDoubleClick}
      onBlur={() => {
        // the defer is to ensure the document.activeElement has been updated if we click a button
        // on a hovercard. Otherwise it will just be document.body
        window.setTimeout(() => {
          if (!hovercardToggleRef.current?.getHovercardNode()?.contains(document.activeElement)) {
            hovercardToggleRef.current?.close();
          }
        }, 0);
      }}
      // make this element focusable/blurrable, which adds the green border
      tabIndex={0}
    >
      {(() => {
        switch (type) {
          case "enum":
            return (
              <HovercardToggle
                disabled
                ref={hovercardToggleRef}
                contents={() => (
                  <SelectableList
                    items={items}
                    onClick={(item) => onEditCell(item.key as string)}
                  />
                )}
              >
                <span>{text}</span>
              </HovercardToggle>
            );
          case "date":
            return (
              <HovercardToggle
                disabled
                ref={hovercardToggleRef}
                contents={() => (
                  <Calendar
                    onSelectRange={([day]) => {
                      onEditCell(day.toISOString());
                      hovercardToggleRef.current.close();
                    }}
                    selectedRange={[dayjs(text)]}
                    flavor="date"
                  />
                )}
              >
                <span>{text}</span>
              </HovercardToggle>
            );
          case "string":
          case "number":
          default:
            return (
              <TextEditor
                ref={inputRef}
                key={text}
                text={text}
                onStopEditing={(newText) => onEditCell(validateInput(newText, type))}
              />
            );
        }
      })()}
    </span>
  );
}

/**
 * Corrects any input based on the type, which is especially important for input fields where the
 * user can type in anything.
 */
function validateInput(text: string, type: "string" | "number"): number | string {
  switch (type) {
    case "number":
      return window.parseFloat(text.replace(/[,a-zA-Z]/g, ""));
    case "string":
      return text.trim();
    default:
      return text;
  }
}
